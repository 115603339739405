<template>
  <div>
    <b-form-input
      v-model="search"
      class="search-input"
      placeholder="Search..."
    />
    <ul class="mt-5">
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in itemsFiltered"
        :key="`${item.header}_${item.route}_${item.title}`"
        :item="item"
      />
    </ul>
  </div>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  data() {
    return {
      search: '',
    }
  },
  created() {
    this.$root.$on('clicked::link', (e) => {
      if(!this.search) { return; }
      let src_elem = e.srcElement.closest('li');
      if(!src_elem) { return; }
      this.search = null;
      setTimeout(() => {
        document.querySelector('.ps-container').scrollTo(0, src_elem.offsetTop - e.view.innerHeight/2)
      }, 300);
    });
  },
  computed: {
    itemsFiltered() {
      if (!this.search || this.search.length < 2) {
        return this.items
      }
      document.querySelector('.ps-container').scrollTo(0,0)
      let sign = 0
      let newItems = []
      let nearest_header = null;
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        let title = item.title;
        if (!item.title && item.header) {
          nearest_header = item;
          continue;
        }

        if (title.toLowerCase().search(this.search.toLowerCase()) >= 0) {
          let existed_header = newItems.find((new_item) => new_item.header && new_item.header === nearest_header.header);
          if(!existed_header) { newItems.push(nearest_header) }
          newItems.push(item)
        }
      }

      return newItems
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-input {
    width: 85%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }
</style>
