<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    toggle-class="d-flex align-items-center dropdown-user-link"
    right
    v-if="currentLocale"
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { setUserData } from "@/libs/cookie";
import adminUserService from "@/modules/admin_user/service";

export default {
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return (
        this.locales.find((l) => l.locale === this.$i18n.locale) ||
        this.locales.find((l) => l.locale === "en")
      );
    },
    userData() {
      return this.$store.getters["auth/userData"];
    },
    locales() {
      const locales = this.$store.state.language.languages.map((item) => {
        return {
          locale: item.code,
          img: item.flag || require(`@/assets/images/flags/${item.code}.png`),
          name: item.name,
        };
      });
      return locales;
    },
  },
  created() {
    this.setAuthLanguage();
  },
  methods: {
    async changeLanguage(locale) {
      this.$i18n.locale = locale;
      let response = await adminUserService.updateLanguage({
        language: locale,
      });
      if (response.data.type == "SUCCESS") {
        setUserData({ ...this.userData, language: locale });
        this.setAuthLanguage();
      }
    },
    setAuthLanguage() {
      this.$store.dispatch("auth/setAuthLanguage");
    },
  },
  // setup() {
  //   /* eslint-disable global-require */
  //   const locales = [
  //     {
  //       locale: "en",
  //       img: require(`@/assets/images/flags/en.png`),
  //       name: "English",
  //     },
  //     {
  //       locale: "vi",
  //       img: require("@/assets/images/flags/vi.png"),
  //       name: "Vietnam",
  //     },
  //     {
  //       locale: "th",
  //       img: require("@/assets/images/flags/th.png"),
  //       name: "Thailand",
  //     },
  //     {
  //       locale: "id",
  //       img: require("@/assets/images/flags/id.png"),
  //       name: "Indonesian",
  //     },
  //     {
  //       locale: "zh",
  //       img: require("@/assets/images/flags/zh.png"),
  //       name: "China",
  //     },
  //     {
  //       locale: "ja",
  //       img: require("@/assets/images/flags/ja.png"),
  //       name: "Japan",
  //     },
  //   ];
  //   return {
  //     locales,
  //   };
  // },
};
</script>

<style>
</style>
