/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

export default [
  {
    header: "Content",
    resource: "auth"
  },
  {
    title: "Pronun",
    route: "pronun",
    icon: "MicIcon",
    resource: "auth"
  },
  {
    title: "Vocab",
    route: "vocab-topic",
    icon: "BookIcon",
    resource: "auth"
  },
  {
    title: "Arena Speaking",
    route: "arena-category",
    icon: "AnchorIcon",
    resource: "auth"
  },
  {
    title: "Arena Listening",
    route: "listening-topic",
    icon: "HeadphonesIcon",
    resource: "auth"
  },
  {
    title: "Courses",
    route: "courses",
    icon: "BookOpenIcon",
    resource: "auth"
  },
  {
    title: "Post",
    route: "post",
    icon: "EditIcon",
    resource: "auth"
  },
  {
    title: "Sale Popup",
    route: "sale-popup",
    icon: "ShoppingBagIcon",
    resource: "auth"
  },
  {
    title: "IAP Popup",
    route: "iap-popup",
    icon: "ShoppingBagIcon",
    resource: "auth"
  },
  {
    title: "Information",
    route: "info-company",
    icon: "InfoIcon",
    resource: "auth"
  },
  // {
  //   title: 'Badges',
  //   route: 'badges',
  //   icon: 'LifeBuoyIcon',
  //   resource: 'auth',
  // },
  //-----------------------------------
  {
    header: "Offer",
    resource: "auth"
  },
  {
    title: "Special Offer",
    route: "offer",
    icon: "ShoppingBagIcon",
    resource: "auth"
  },
  {
    title: "Landing Page",
    route: "landing-page",
    icon: "BookIcon",
    resource: "auth"
  },
  {
    header: "Breaking Goga",
    resource: "auth"
  },
  {
    title: "Lesson",
    route: "breaking-lesson",
    icon: "BookIcon",
    resource: "auth"
  },
  //----------------------------------------
  {
    header: "Play",
    resource: "auth"
  },
  {
    title: "Stage levels",
    route: "stage_level",
    icon: "LayersIcon",
    resource: "auth"
  },
  {
    title: "Stage",
    route: "arena-stage",
    icon: "SearchIcon",
    resource: "auth"
  },
  // {
  //   title: 'Milestone',
  //   route: 'arena-milestone',
  //   icon: 'MapPinIcon',
  //   resource: 'auth',
  // },
  {
    title: "Word Pool",
    route: "word-pool",
    icon: "ImageIcon",
    resource: "auth"
  },
  {
    title: "Stage Config",
    route: "stage-config",
    icon: "SettingsIcon",
    resource: "auth"
  },
  {
    title: "User Stage",
    route: "user-stage",
    icon: "UserIcon",
    resource: "auth"
  },
  {
    title: "Grammar card",
    route: "grammar-card",
    icon: "ImageIcon",
    resource: "auth"
  },
  //----------------------------------------
  {
    header: "Goga rank",
    resource: "auth"
  },
  {
    title: "Cap Times & Score",
    route: "goga-rank-cap-time",
    icon: "ClockIcon",
    resource: "auth"
  },
  {
    title: "Reward",
    route: "goga-rank-reward",
    icon: "GiftIcon",
    resource: "auth"
  },
  {
    title: "Season",
    route: "goga-rank-season",
    icon: "SunIcon",
    resource: "auth"
  },
  {
    title: "Topic",
    route: "goga-rank-topic",
    icon: "BookIcon",
    resource: "auth"
  },
  {
    title: "Virtual user",
    route: "goga-rank-virtual-user",
    icon: "UsersIcon",
    resource: "auth"
  },
  //-----------------------------------
  {
    header: "Goga Exam",
    resource: "auth"
  },
  {
    title: "Goga Exam",
    route: "goga-exam",
    icon: "EditIcon",
    resource: "auth"
  },
  {
    title: "Goga Exam Level",
    route: "goga-exam-level",
    icon: "EditIcon",
    resource: "auth"
  },
  // social plus
  {
    header: "Native11 Social+",
    resource: "auth"
  },
  {
    title: "Topic",
    route: "social-plus-topic",
    icon: "BookIcon",
    resource: "auth"
  },
  {
    title: "Native Talk",
    route: "native-talk",
    icon: "CastIcon",
    resource: "auth"
  },
  {
    title: "Native Talk Report",
    route: "native-talk-report",
    icon: "PieChartIcon",
    resource: "auth"
  },
  {
    title: "Native11 teacher",
    route: "native11-teacher-list",
    icon: "UsersIcon",
    resource: "social-plus"
  },
  {
    title: "Native11 Payment",
    route: "native11-payment",
    icon: "CreditCardIcon",
    resource: "social-plus"
  },
  {
    title: "Native11 Speaking Test",
    route: "native11-speaking-test",
    icon: "CreditCardIcon",
    resource: "social-plus"
  },
  {
    title: "Native 1-1 Report",
    icon: "PieChartIcon",
    name: "native11-report",
    children: [
      {
        title: "Teacher rating student",
        route: "native11-student-rating",
        resource: "auth"
      },
      {
        title: "Student rating teacher",
        route: "native11-teacher-rating",
        resource: "auth"
      },
      {
        title: "Class Infomation",
        route: "native11-room-info-report",
        resource: "auth"
      },
      {
        title: "Teacher Report",
        route: "native11-teacher-report",
        resource: "auth"
      }
    ]
  },
  //----------------------------------------
  {
    header: "GOGA EARN",
    resource: "goga-earn"
  },
  {
    title: "Config",
    route: "earn-fomula-config",
    icon: "SettingsIcon",
    resource: "goga-earn"
  },
  {
    title: "Products",
    route: "earn-product",
    icon: "PackageIcon",
    resource: "goga-earn"
  },
  {
    title: "IAP Products",
    route: "iap-product",
    icon: "PackageIcon",
    resource: "goga-earn"
  },
  {
    title: "Gifts",
    route: "earn-gift",
    icon: "GiftIcon",
    resource: "goga-earn"
  },
  {
    title: "Lucky Ticket",
    route: "earn-luckyticket",
    icon: "CreditCardIcon",
    resource: "goga-earn"
  },
  {
    title: "Images",
    icon: "ImageIcon",
    resource: "auth",
    children: [
      {
        title: "NFT",
        route: "minting-nft-image-pool"
      },
      {
        title: "Chipset",
        route: "nft-chipset-imgs",
        resource: "goga- earn"
      }
    ]
  },
  {
    title: "Report",
    icon: "PieChartIcon",
    resource: "auth",
    children: [
      {
        title: "Early Claim",
        route: "early-claim"
      },
      {
        title: "Gift",
        route: "gift-report"
      },
      {
        title: "GGT Drop",
        route: "early-claim-total-ggt"
      },
      {
        title: "Transfer",
        route: "transfer-record",
        resource: "goga-earn"
      },
      {
        title: "TransferNft",
        route: "transfer-nft-record",
        resource: "goga-earn"
      }
    ]
  },
  //-----------------------------------
  {
    header: "MISSION",
    resource: "auth"
  },
  {
    title: "Mission",
    icon: "AwardIcon",
    children: [
      {
        title: "Newbie",
        route: "mission-newbie",
        resource: "auth"
      },
      {
        title: "Daily",
        route: "mission-daily",
        resource: "auth"
      },
      {
        title: "Event",
        route: "mission-event",
        resource: "auth"
      }
    ]
  },
  {
    title: "Rewards",
    route: "mission-rewards",
    icon: "GiftIcon",
    resource: "auth"
  },
  {
    title: "GGT Rate",
    route: "random-ggt-reward-rate",
    icon: "DollarSignIcon",
    resource: "auth"
  },
  //------------------
  {
    header: "Social Game",
    resource: "auth"
  },
  {
    title: "Breaking Quiz",
    route: "breaking-quiz",
    icon: "BookIcon",
    resource: "auth"
  },
  {
    title: "Create Word",
    route: "create-word",
    icon: "BookIcon",
    resource: "auth"
  },
  {
    title: "Dictionary",
    route: "dictionary",
    icon: "BookIcon",
    resource: "auth"
  },
  {
    title: "Bot",
    route: "bot",
    icon: "UsersIcon",
    resource: "auth"
  },
  //----------------------------------------

  {
    header: "MANAGEMENT",
    resource: "auth"
  },
  {
    title: "Config",
    route: "config",
    icon: "SettingsIcon",
    resource: "auth"
  },
  {
    title: "Language",
    route: "language",
    icon: "FlagIcon",
    resource: "auth"
  },
  {
    title: "User",
    route: "user",
    icon: "UserIcon",
    resource: "auth"
  },
  {
    title: "Activation",
    route: "activation",
    icon: "LockIcon",
    resource: "auth"
  },
  {
    title: "Product",
    route: "product",
    icon: "KeyIcon",
    resource: "auth"
  },
  {
    title: "Is Tag",
    route: "is-tag-management",
    icon: "RssIcon",
    resource: "auth"
  },
  {
    title: "Survey",
    route: "survey",
    icon: "TrelloIcon",
    resource: "auth"
  },
  {
    title: "Feedback",
    route: "feedback",
    icon: "StarIcon",
    resource: "auth"
  },
  {
    title: "User Report",
    route: "user-report",
    icon: "TwitchIcon",
    resource: "auth"
  },
  {
    title: "Learn Time Report",
    route: "user-learn-time-report",
    icon: "TwitchIcon",
    resource: "auth"
  },
  {
    title: "Create account",
    route: "create-account",
    icon: "UserIcon",
    resource: "auth"
  },
  //----------------------------------------
  {
    header: "NOTIFICATIONS",
    resource: "auth"
  },
  {
    title: "Notification",
    route: "notification",
    icon: "BellIcon",
    resource: "goga-earn"
  },
  {
    title: "Mails management",
    route: "mail",
    icon: "MailIcon",
    resource: "auth"
  },
  // AI Tutor
  {
    header: "AI Tutor",
    resource: "auth"
  },
  {
    title: "Banner",
    route: "ai_banner",
    icon: "ImageIcon",
    resource: "auth"
  },
  {
    title: "Careers",
    route: "ai_career",
    icon: "MoreVerticalIcon",
    resource: "auth"
  },
  {
    title: "AI Test",
    icon: "CodesandboxIcon",
    children: [
      {
        title: "Pronun - Zh",
        route: "ai-test.pronun_zh",
        resource: "auth"
      },
      {
        title: "Speech2Text - Zh",
        route: "ai-test.s2t_zh",
        resource: "auth"
      }
    ]
  },
  // {
  //   title: 'Positions',
  //   route: 'ai_position',
  //   icon: 'MoreVerticalIcon',
  //   resource: 'auth',
  // },
  {
    title: "Tags",
    route: "ai_tag",
    icon: "TagIcon",
    resource: "auth"
  },
  {
    title: "Scenario",
    route: "ai_scenario",
    icon: "FilmIcon",
    resource: "auth"
  },
  {
    title: "AI Chat",
    route: "ai-chats",
    icon: "MessageCircleIcon",
    resource: "auth"
  },
  {
    title: "AI Prompt",
    route: "ai-prompt",
    icon: "LayersIcon",
    resource: "ai-prompt"
  },
  {
    title: "AI Report",
    route: "ai_report",
    icon: "ActivityIcon",
    resource: "auth"
  },
  // TOOL
  {
    header: "TOOL",
    resource: "auth"
  },
  {
    title: "UTest",
    route: "utest.run",
    icon: "CodesandboxIcon",
    resource: "auth"
  },
  {
    title: "User play time",
    route: "user-play-time-record",
    icon: "ActivityIcon",
    resource: "auth"
  },
  // admin
  {
    header: "ADMIN",
    resource: "auth"
  },
  {
    title: "Admin User",
    route: "admin-user",
    icon: "UsersIcon",
    resource: "admin"
  },
  {
    title: "Action Records",
    route: "action-record",
    icon: "ActivityIcon",
    resource: "auth"
  }
];
